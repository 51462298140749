<template>
  <div class="wrapper">
    <header>
      <div class="flex first" v-if="province != '河南省'">
        <p class="area">选择地区:</p>
        <selectCity
            level="1"
            @getVal="setCityValue"
            style="width: 1050px"
        ></selectCity>
      </div>
      <div class="flex second">
        <p class="area">是否参会:</p>
        <ul class="flex join">
          <li
              class="flexs"
              v-for="(item, index) in tab"
              :key="index"
              :class="{ active: active == index }"
              @click="tabs(index)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
    </header>
    <section>
      <ul
          class="job"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <li>
          <div class="wrap flex" v-for="item in jobList" :key="item.id">
            <!-- <router-link
              :to="'/enterprise/netDetail?id='+item.id"
              target="_blank"
              :title="item.name+'详情'"
            > -->
            <div class="imgs flexs relative">
              <img
                  :src="item.imgUrl"
                  alt
                  :class="item.imgUrl? 'img' : 'err_image'"
                  @click.stop="goRouter(
                        '/enterprise/joinJob/',
                        item.id,
                        item.name,
                        item.ingType
                  )"
              />
              <img src="~/static/onlieJ/netJob/apply.png" alt="" v-if="item.ingType == '1'" class="status">
              <img src="~/static/onlieJ/netJob/processing.png" alt="" v-if="item.ingType == '2'" class="status">
              <img src="~/static/onlieJ/netJob/over.png" alt="" v-if="item.ingType == '3'" class="status">
            </div>
            <!-- </router-link> -->

            <div class="text relative" @click="goRouter('/enterprise/netDetail',item.id)">
              <h3 class="flex">
                <!--                {{item.ingType}}-->
                <!-- <router-link
                  :to="'/enterprise/netDetail?id='+item.id"
                  :title="item.name+'详情'"
                  target="_blank"
                ></router-link> -->
              <p class="length3 line_clamp1">  {{ item.name }}</p>
                <span
                    class="marL10"
                    :class="item.jobFairStatics.jobs > '0' ? 'ending':'apply'"
                    v-if="item.ingType == '2' && item.jobFairStatics.jobs">
                  {{
                    item.jobFairStatics.jobs > '0' ? "【已参会】" : "【可参会】"
                  }}</span>

              </h3>
              <div class="number" v-if="item.jobFairStatics.jobs">
                <p v-if="item.jobFairStatics.jobs > '0'">已发布<span>{{item.jobFairStatics.jobs}}</span>个参会的职位</p>
              </div>
              <ul>
                <li>举办地点：{{ item.provinceid }}</li>
                <li>报名时间：{{ item.sdate }}至{{ item.edate }}</li>
                <li>进行时间：{{ item.sdate }}至{{ item.edate }}</li>
              </ul>
            </div>
            <div class="butn flexs">
              <ul>
                <li>
                  <el-button
                      type="primary"
                      @click.stop="goRouter(
                        '/enterprise/joinJob/',
                        item.id,
                        item.name,
                        item.ingType
                      )"
                      v-if="item.ingType != '3'"><img src="~/static/onlieJ/netJob/document.png" alt="" class="middle marR5">参会报名
                  </el-button>
                  <el-button
                      class="btn_info"
                      type="info"
                      v-else><img src="~/static/onlieJ/netJob/black.png" alt="" class="middle marR5">参会报名
                  </el-button>
                </li>
                <li v-if="item.jobFairStatics.jobs">
                  <el-button
                      @click.stop="goRouter(
                        '/enterprise/resumes/',
                        item.id,
                        item.name,
                        item.ingType
                      )"
                      type="primary"
                      v-if="item.jobFairStatics.jobs > '0'"
                  ><img src="~/static/onlieJ/netJob/look.png" alt="" class="middle marR5">查看简历
                  </el-button>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
      <status
          v-if="status.isHide == 1"
          :type="status.type"
          :des="status.des"
      ></status>
    </section>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.pageNum"
          :page-size="params.pageSize"
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next, total, jumper"
          :total="params.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import selectCity from "components/search/selectCity";

export default {
  components: {
    selectCity,
  },
  data() {
    return {
      active: 0,
      loading: false,
      level: 1,
      tab: ["全部", "可参会", "已参会"],
      params: {
        city: "",
        isJoin: "",
        pageNum: 1,
        pageSize: 10,
        total:0
      },
      status: {
        isHide: 0,
        des: "",
        type: "",
      },
      jobList: [],
      province:'',
    };
  },
  watch: {
    params: {
      handler(newName, oldName) {
        this.jobfairList();
      },
      deep: true,
    },
  },
  methods: {
    tabs(index) {
      this.active = index;
      if (this.active == 0) {
        this.params.isJoin = "";
        this.jobfairList();
      } else if (this.active == 1) {
        this.params.isJoin = 0;
        this.jobfairList();
      } else {
        this.params.isJoin = 1;
        this.jobfairList();
      }
      this.province = localStorage.getItem('locationProvince');
    },
    setCityValue(val) {
      this.params.city = val[0];
    },
    async jobfairList(index) {
      this.loading = true;
      try {
        let res = await this.$api.jobfairList(this.params);
        if (res.data.success) {
          this.jobList = res.data.data.records;
          this.params.total = res.data.data.total;
          this.loading = false;
          if (this.jobList.length <= 0) {
            this.status.isHide = 1;
            this.status.type = "post";
            this.status.des = "暂无匹配的招聘会!";
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
      }
    },
    goRouter(url, id, name, ingType) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
          name: name,
          ingType: ingType,
        },
      });
      window.open(href);
    },
    handleSizeChange(val) {
    },
    handleCurrentChange: function (val) {
      this.params.pageNum = val;
      this.jobfairList()
    },
  },
  created() {
    this.tabs(0);
  },
};
</script>

<style lang="less" scoped>
@import "~assets/css/enterprise/netJob";
.length3{
  width: 80%;
}
</style>