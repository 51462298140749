var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('header',[(_vm.province != '河南省')?_c('div',{staticClass:"flex first"},[_c('p',{staticClass:"area"},[_vm._v("选择地区:")]),_c('selectCity',{staticStyle:{"width":"1050px"},attrs:{"level":"1"},on:{"getVal":_vm.setCityValue}})],1):_vm._e(),_c('div',{staticClass:"flex second"},[_c('p',{staticClass:"area"},[_vm._v("是否参会:")]),_c('ul',{staticClass:"flex join"},_vm._l((_vm.tab),function(item,index){return _c('li',{key:index,staticClass:"flexs",class:{ active: _vm.active == index },on:{"click":function($event){return _vm.tabs(index)}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]),_c('section',[_c('ul',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"job",attrs:{"element-loading-text":"拼命加载中","element-loading-spinner":"el-icon-loading"}},[_c('li',_vm._l((_vm.jobList),function(item){return _c('div',{key:item.id,staticClass:"wrap flex"},[_c('div',{staticClass:"imgs flexs relative"},[_c('img',{class:item.imgUrl? 'img' : 'err_image',attrs:{"src":item.imgUrl,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.goRouter(
                      '/enterprise/joinJob/',
                      item.id,
                      item.name,
                      item.ingType
                )}}}),(item.ingType == '1')?_c('img',{staticClass:"status",attrs:{"src":require("static/onlieJ/netJob/apply.png"),"alt":""}}):_vm._e(),(item.ingType == '2')?_c('img',{staticClass:"status",attrs:{"src":require("static/onlieJ/netJob/processing.png"),"alt":""}}):_vm._e(),(item.ingType == '3')?_c('img',{staticClass:"status",attrs:{"src":require("static/onlieJ/netJob/over.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"text relative",on:{"click":function($event){return _vm.goRouter('/enterprise/netDetail',item.id)}}},[_c('h3',{staticClass:"flex"},[_c('p',{staticClass:"length3 line_clamp1"},[_vm._v(" "+_vm._s(item.name))]),(item.ingType == '2' && item.jobFairStatics.jobs)?_c('span',{staticClass:"marL10",class:item.jobFairStatics.jobs > '0' ? 'ending':'apply'},[_vm._v(" "+_vm._s(item.jobFairStatics.jobs > '0' ? "【已参会】" : "【可参会】"))]):_vm._e()]),(item.jobFairStatics.jobs)?_c('div',{staticClass:"number"},[(item.jobFairStatics.jobs > '0')?_c('p',[_vm._v("已发布"),_c('span',[_vm._v(_vm._s(item.jobFairStatics.jobs))]),_vm._v("个参会的职位")]):_vm._e()]):_vm._e(),_c('ul',[_c('li',[_vm._v("举办地点："+_vm._s(item.provinceid))]),_c('li',[_vm._v("报名时间："+_vm._s(item.sdate)+"至"+_vm._s(item.edate))]),_c('li',[_vm._v("进行时间："+_vm._s(item.sdate)+"至"+_vm._s(item.edate))])])]),_c('div',{staticClass:"butn flexs"},[_c('ul',[_c('li',[(item.ingType != '3')?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.goRouter(
                      '/enterprise/joinJob/',
                      item.id,
                      item.name,
                      item.ingType
                    )}}},[_c('img',{staticClass:"middle marR5",attrs:{"src":require("static/onlieJ/netJob/document.png"),"alt":""}}),_vm._v("参会报名 ")]):_c('el-button',{staticClass:"btn_info",attrs:{"type":"info"}},[_c('img',{staticClass:"middle marR5",attrs:{"src":require("static/onlieJ/netJob/black.png"),"alt":""}}),_vm._v("参会报名 ")])],1),(item.jobFairStatics.jobs)?_c('li',[(item.jobFairStatics.jobs > '0')?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.goRouter(
                      '/enterprise/resumes/',
                      item.id,
                      item.name,
                      item.ingType
                    )}}},[_c('img',{staticClass:"middle marR5",attrs:{"src":require("static/onlieJ/netJob/look.png"),"alt":""}}),_vm._v("查看简历 ")]):_vm._e()],1):_vm._e()])])])}),0)]),(_vm.status.isHide == 1)?_c('status',{attrs:{"type":_vm.status.type,"des":_vm.status.des}}):_vm._e()],1),_c('div',{staticClass:"pages"},[_c('el-pagination',{staticClass:"work_pagination",attrs:{"background":"","current-page":_vm.params.pageNum,"page-size":_vm.params.pageSize,"prev-text":"上一页","next-text":"下一页","layout":"prev, pager, next, total, jumper","total":_vm.params.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){return _vm.$set(_vm.params, "pageNum", $event)},"update:current-page":function($event){return _vm.$set(_vm.params, "pageNum", $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }